<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-overlay :show="loading">
            <tabs
                ref="tabs"
                :data="formData"
            />

            <div class="divider my-2">
              <div class="divider-text">
                <circle-icon></circle-icon>
              </div>
            </div>

            <b-col md="12">
              <b-row>
                <h4 class="card-title">Érvényesség kezdete</h4>
              </b-row>
              <b-row>
                <b-form-group
                    label="Érvényesség kezdete"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="érvényesség kezdete"
                      rules="required"
                      vid="validFrom"
                  >
                    <flat-pickr
                        v-model="formData.validFrom"
                        class="form-control"
                        :config="flatPickRConfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-row>
            </b-col>

            <div class="divider my-2">
              <div class="divider-text">
                <circle-icon></circle-icon>
              </div>
            </div>

            <b-col md="12">
              <b-row>
                <h4 class="card-title">Kedvezmények</h4>
              </b-row>
              <b-row v-for="(pricingType, pricingTypeKey) in formData.baseFixFees.discount">
                <b-col md="12">
                  <b-row v-for="(discount, discountKey) in pricingType">
                    <b-form-group
                        :label="$t('pricing.'+pricingTypeKey)+' '+$t('pricing.'+discountKey)"
                    >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('pricing.'+pricingTypeKey)+' '+$t('pricing.'+discountKey)"
                          rules="required"
                          :vid="pricingTypeKey"
                      >
                        <b-input-group>
                          <b-form-input
                              v-model="formData.baseFixFees.discount[pricingTypeKey][discountKey].price"
                              type="number"
                              :state="errors.length > 0 ? false:null"
                          />
                          <b-input-group-append>
                            <b-input-group-text>{{ $t('pricing.' + discount.calculation_type) }}</b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>

            <div class="divider my-2">
              <div class="divider-text">
                <circle-icon></circle-icon>
              </div>
            </div>

            <b-col md="12">
              <b-row>
                <h4 class="card-title">Fix díjak</h4>
              </b-row>
              <b-row v-for="(pricingType, pricingTypeKey) in formData.baseFixFees.fee">
                <b-col md="12">
                  <b-row v-for="(fee, feeKey) in pricingType">
                    <b-form-group
                        :label="$t('pricing.'+pricingTypeKey)+' '+$t('pricing.'+feeKey)"
                    >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('pricing.'+pricingTypeKey)+' '+$t('pricing.'+feeKey)"
                          rules="required"
                          :vid="pricingTypeKey"
                      >
                        <b-input-group>
                          <b-form-input
                              v-model="formData.baseFixFees.fee[pricingTypeKey][feeKey].price"
                              type="number"
                              :state="errors.length > 0 ? false:null"
                          />
                          <b-input-group-append>
                            <b-input-group-text>{{ $t('pricing.' + fee.calculation_type) }}</b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>

            <div class="divider my-2">
              <div class="divider-text">
                <circle-icon></circle-icon>
              </div>
            </div>

            <b-col md="12" class="mb-3">
              <b-row>
                <h4 class="card-title">Opciók</h4>
              </b-row>
              <b-row v-for="(option, optionKey) in formData.options">
                <b-form-group
                    :label="$t('pricing.'+optionKey)"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('pricing.'+optionKey)"
                      rules="required"
                      :vid="optionKey"
                  >
                    <b-input-group>
                      <b-form-input
                          v-model="formData.options[optionKey].price"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                      />
                      <b-input-group-append>
                        <b-input-group-text>{{ $t('pricing.' + option.calculation_type) }}</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-row>
            </b-col>

          </b-overlay>

          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import tabs from '@/views/pages/_components/_tabs/_pricing/update.vue'
import {
  BOverlay,
  BButton,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BFormInput
} from "bootstrap-vue"
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import {CircleIcon} from 'vue-feather-icons'
import {required} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    BOverlay,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    tabs,
    cancelAndGOTOPreviousPage,
    CircleIcon,
    flatPickr
  },
  data() {
    return {
      formData: {
        baseFixFees: {
          discount: '',
          fee: ''
        },
        baseVariableFees: {},
        options: {},
        validFrom: ''
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      loading: false,
      required
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.loading = true
      this.$store.dispatch('fetchReservationPricing').then(response => {
        this.formData = response.data
        this.formData.validFrom = response.data.now
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm() {
      let baseVariableFees = this.$refs.tabs.refreshTabData()
      let baseFixFees = this.formData.baseFixFees
      let options = this.formData.options
      this.loading = true

      this.$store.dispatch('updateReservationPricing',
          {
            baseVariableFees: baseVariableFees,
            baseFixFees: baseFixFees,
            options: options,
            validFrom: this.formData.validFrom
          }
      ).then(res => {
        this.$helpers.showSuccessToast('Az árazás frissítése sikeres volt.')
        this.initData()
      }).finally(() => {
        this.loading = false
      });
    }
  }
}
</script>
