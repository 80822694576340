var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":"Szerkesztés"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('tabs',{ref:"tabs",attrs:{"data":_vm.formData}}),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_c('circle-icon')],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('h4',{staticClass:"card-title"},[_vm._v("Érvényesség kezdete")])]),_c('b-row',[_c('b-form-group',{attrs:{"label":"Érvényesség kezdete"}},[_c('validation-provider',{attrs:{"name":"érvényesség kezdete","rules":"required","vid":"validFrom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.validFrom),callback:function ($$v) {_vm.$set(_vm.formData, "validFrom", $$v)},expression:"formData.validFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_c('circle-icon')],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('h4',{staticClass:"card-title"},[_vm._v("Kedvezmények")])]),_vm._l((_vm.formData.baseFixFees.discount),function(pricingType,pricingTypeKey){return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},_vm._l((pricingType),function(discount,discountKey){return _c('b-row',[_c('b-form-group',{attrs:{"label":_vm.$t('pricing.'+pricingTypeKey)+' '+_vm.$t('pricing.'+discountKey)}},[_c('validation-provider',{attrs:{"name":_vm.$t('pricing.'+pricingTypeKey)+' '+_vm.$t('pricing.'+discountKey),"rules":"required","vid":pricingTypeKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.baseFixFees.discount[pricingTypeKey][discountKey].price),callback:function ($$v) {_vm.$set(_vm.formData.baseFixFees.discount[pricingTypeKey][discountKey], "price", $$v)},expression:"formData.baseFixFees.discount[pricingTypeKey][discountKey].price"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t('pricing.' + discount.calculation_type)))])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),1)],1)})],2),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_c('circle-icon')],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('h4',{staticClass:"card-title"},[_vm._v("Fix díjak")])]),_vm._l((_vm.formData.baseFixFees.fee),function(pricingType,pricingTypeKey){return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},_vm._l((pricingType),function(fee,feeKey){return _c('b-row',[_c('b-form-group',{attrs:{"label":_vm.$t('pricing.'+pricingTypeKey)+' '+_vm.$t('pricing.'+feeKey)}},[_c('validation-provider',{attrs:{"name":_vm.$t('pricing.'+pricingTypeKey)+' '+_vm.$t('pricing.'+feeKey),"rules":"required","vid":pricingTypeKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.baseFixFees.fee[pricingTypeKey][feeKey].price),callback:function ($$v) {_vm.$set(_vm.formData.baseFixFees.fee[pricingTypeKey][feeKey], "price", $$v)},expression:"formData.baseFixFees.fee[pricingTypeKey][feeKey].price"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t('pricing.' + fee.calculation_type)))])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),1)],1)})],2),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_c('circle-icon')],1)]),_c('b-col',{staticClass:"mb-3",attrs:{"md":"12"}},[_c('b-row',[_c('h4',{staticClass:"card-title"},[_vm._v("Opciók")])]),_vm._l((_vm.formData.options),function(option,optionKey){return _c('b-row',[_c('b-form-group',{attrs:{"label":_vm.$t('pricing.'+optionKey)}},[_c('validation-provider',{attrs:{"name":_vm.$t('pricing.'+optionKey),"rules":"required","vid":optionKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.options[optionKey].price),callback:function ($$v) {_vm.$set(_vm.formData.options[optionKey], "price", $$v)},expression:"formData.options[optionKey].price"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v(_vm._s(_vm.$t('pricing.' + option.calculation_type)))])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)})],2)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }