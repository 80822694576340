<template>
  <b-tabs content-class="mt-3">
    <price-table-tab
        v-for="(pricingType, key) in data.baseVariableFees"
        :tabTitle="key"
        :data="pricingType"
        :key="$helpers.slug(key)"
        :ref="('tab-' + key)"
    />
  </b-tabs>
</template>

<script>
import {BTabs,} from 'bootstrap-vue'
import priceTableTab from '@/views/pages/_components/_tab/_pricing/_update/priceTable.vue'

export default {
  components: {
    BTabs,
    priceTableTab,
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    refreshTabData() {

      let tmpData = {};
      Object.keys(this.data.baseVariableFees).forEach(key => {
        tmpData[key] = this.$refs[`tab-${key}`][0].getData()
      })

      return tmpData;
    },
  }
}
</script>

<style scoped>

</style>
