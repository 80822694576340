<template>
  <b-tab>
    <template #title>
      <strong>
        {{ tabTitle }}
      </strong>
    </template>
    <div>
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-table-simple
            responsive
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th class="align-center" colspan="2" rowspan="2">
                {{ tabTitle }} árazás
              </b-th>
              <b-th class="align-center" :colspan="headerColspan">
                résztvevők száma
              </b-th>
            </b-tr>
            <b-tr>
              <b-th class="align-center" v-for="key in headerColspanOptions">{{ key }} fő</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(day, dayKey, dayIndex) in data">
              <!-- make it dynamic -->
              <b-th v-if="dayIndex ===0" :rowspan="Object.keys(data).length">
                napok száma
              </b-th>
              <b-td class="text-right no-wrap" rowspan="1">
                {{ dayKey }} nap
              </b-td>
              <b-td v-for="(dayType, dayTypeKey, dayTypeIndex) in day"
                    :key="dayTypeKey"
                    rowspan="1">
                <b-table-simple
                    class="no-padding"
                    responsive
                >
                  <b-tbody>
                    <tr>
                      <td class="font-weight-bold" v-for="(priceRow, priceKey, priceIndex) in dayType">{{ priceKey }}</td>
                    </tr>
                    <tr>
                      <td v-for="(priceRow, priceKey, priceIndex) in dayType">
                        <b-form-input
                            type="number"
                            v-model="data[dayKey][dayTypeKey][priceKey]"
                            min="0"
                        ></b-form-input>
                      </td>
                    </tr>
                  </b-tbody>
                </b-table-simple>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </div>
  </b-tab>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BTab,
  BOverlay,
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTab,
    BOverlay,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormInput,
    BButton,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    tabTitle: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      headerColspan: 1,
      headerColspanOptions: [],
      tabData: [],
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
        this.initTab()
      },
      deep: true
    }
  },
  created() {
    this.initTab()
  },
  methods: {
    initTab() {
      Object.keys(this.data).forEach(rowKey => {
        // Header colspan init
        if (Object.keys(this.data[rowKey]).length > this.headerColspan) {
          this.headerColspan = Object.keys(this.data[rowKey]).length
        }
        Object.keys(this.data[rowKey]).forEach(colKey => {
          if (!this.headerColspanOptions.includes(colKey)) {
            this.headerColspanOptions.push(colKey)
          }
        })
      })
    },
    getData(){
      return this.data
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style scoped>
.align-center {
  text-align: center;
  vertical-align: middle;
}
.no-wrap{
  width: 1%;
  white-space: nowrap;
}
.no-padding td{
  padding: 3px 3px 0 3px;
  border: 0;
}
</style>
